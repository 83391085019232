import React, { useState, useEffect } from "react";
import axios from "axios";
import FetchData from "../components/FetchData"; // Adjust the path according to your project structure

const Admin = () => {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);

  useEffect(() => {
    axios
      .get(
        "https://9c480nh818.execute-api.eu-north-1.amazonaws.com/prod/tables"
      )
      .then((response) => {
        setTables(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const handleTableClick = (tableName) => {
    setSelectedTable(tableName);
  };

  return (
    <div className="admin-page">
      <h1>Admin Page</h1>
      <h2>Tables</h2>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {tables.map((table, index) => (
          <div
            key={index}
            onClick={() => handleTableClick(table)}
            className={`table-name ${
              table === selectedTable ? "selected" : ""
            }`}
          >
            {table}
          </div>
        ))}
      </div>
      {selectedTable && (
        <FetchData key={selectedTable} tableName={selectedTable} />
      )}
    </div>
  );
};

export default Admin;
