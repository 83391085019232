import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "./Spinner"; // Adjust the path according to your project structure

const FetchData = ({ tableName }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        `https://9c480nh818.execute-api.eu-north-1.amazonaws.com/prod/tables/${tableName}`
      )
      .then((response) => {
        setData(response.data.slice(0, 10));
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [tableName]);

  if (loading) {
    return <Spinner />;
  }

  if (data.length === 0) {
    return <p>Table is empty</p>;
  }

  return (
    <table>
      <thead>
        <tr>
          {data[0] &&
            Object.keys(data[0]).map((heading) => (
              <th key={heading}>{heading}</th>
            ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {Object.values(item).map((value, i) => (
              <td key={i}>{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default FetchData;
