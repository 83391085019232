import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Logo from "./components/Logo";
import "./App.scss";
import Admin from "./components/Admin";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/admin" element={<Admin />} />
          <Route
            path="/"
            element={
              <div className="root-page">
                <Logo className="logo" />
                {[...Array(20)].map((_, i) => (
                  <div key={i} className="ball" />
                ))}
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
